<template>
  <base-modal-ex v-if="isActive"
    :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="40"
    has-modal-card>
    <header class="modal-card-head">
      <div class="modal-card-title"
        :class="{ 'is-flex-items-center' : showIcon}">
        <span v-if="showIcon"
          class="icon is-medium"
          :class="`has-text-${iconType}`"
          style="margin-right: 0.5rem">
          <i class="mdi mdi-36px"
            :class="`${iconName}`" />
        </span>
        <!-- <slot name="text-title"></slot> -->
        <span>Submit Items</span>
      </div>
    </header>
    <section class="modal-card-body">
      <label class="label">Select item types to submit</label>

      <div class="field">
        <input class="is-checkradio"
          id="submitParts"
          type="checkbox"
          name="submitParts"
          v-model="isSubmitParts"
          :disabled="!hasParts">
        <label for="submitParts">Part Items</label>
      </div>
      <div class="field">
        <input class="is-checkradio"
          id="submitSublets"
          type="checkbox"
          name="submitSublets"
          v-model="isSubmitSublets"
          :disabled="!hasSublets">
        <label for="submitSublets">Sublet Items</label>
      </div>
      <div v-if="value.isAudaBridge"
        class="field">
        <input class="is-checkradio"
          id="submitLabours"
          type="checkbox"
          name="submitLabours"
          v-model="isSubmitLabours"
          :disabled="!hasLabours">
        <label for="submitLabours">Labour Items</label>
      </div>
      <div v-if="value.isAudaBridge"
        class="field">
        <input class="is-checkradio"
          id="submitPaints"
          type="checkbox"
          name="submitPaints"
          v-model="isSubmitPaints"
          :disabled="!hasPaints">
        <label for="submitPaints">Paint Items</label>
      </div>
    </section>
    <footer class="modal-card-foot"
      :style="`justify-content: flex-${buttonLocation}`">
      <button class="button is-success tooltip"
        @click="submit()"
        data-tooltip="Submit"
        :disabled="!isSubmitLabours && !isSubmitPaints && !isSubmitParts && !isSubmitSublets">
        <span class="icon is-small">
          <i class="mdi mdi-18px mdi-upload-multiple" />
        </span>
        <span>Submit</span>
      </button>
      <button class="button tooltip"
        @click="cancel()"
        data-tooltip="Cancel"
        :disabled="cancelDisabled">
        <span>Cancel</span>
      </button>
    </footer>
  </base-modal-ex>
</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'
import { ItemCategoryTypes } from '@/enums'

export default {
  name: 'AudanetSubmitItemsModal',
  components: {
    BaseModalEx
  },
  props: {
    value: null,
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'primary'
    },
    iconName: {
      type: String,
      default: 'mdi-upload-multiple'
    },
    copyDisabled: {
      type: Boolean,
      default: false
    },
    cancelDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isActive: false,
      innerValue: null,
      isSubmitLabours: false,
      isSubmitPaints: false,
      isSubmitParts: false,
      isSubmitSublets: false
    }
  },
  validations: {},
  computed: {
    hasLabours() {
      const items = this.innerValue.labours.filter(i => i.itemType != ItemCategoryTypes.PAINT)
      const miscItems = this.innerValue.miscs
      const mechItems = this.innerValue.others.filter(i => i.itemType != ItemCategoryTypes.MECH)
      return items.length > 0 || miscItems.length > 0 || mechItems.length > 0
    },
    hasPaints() {
      const items = this.innerValue.labours.filter(i => i.itemType === ItemCategoryTypes.PAINT)
      return items.length > 0
    },
    hasParts() {
      return this.innerValue.parts.length > 0
    },
    hasSublets() {
      return this.innerValue.sublets.length > 0
    }
  },
  watch: {
    active(value) {
      this.isActive = value
    }
  },
  created() {
    this.innerValue = this.value
    // this.isSubmitLabours = this.hasLabours
    // this.isSubmitPaints = this.hasPaints
    // this.isSubmitParts = this.hasParts
    // this.isSubmitSublets = this.hasSublets
  },
  mounted() {
    this.isActive = this.active || false
  },
  methods: {
    submit() {
      this.$emit('submit', this.isSubmitLabours, this.isSubmitPaints, this.isSubmitParts, this.isSubmitSublets)
    },
    cancel() {
      this.$emit('cancel')
    }
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
</style>
